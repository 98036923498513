<template>
    <div class="cannel-view">
        <div class="cannel-top-title"><p class="font">认证失败</p></div>
        <div class="cannel-real-name-text"><p class="font">当前实名认证已失败，具体原因如下</p></div>
        <div class="cannel-real-name-display">
            <img class="cannel-icon" src="../assets/img/cannel.svg" alt="">
            <p class="cannel-text">{{cannelText}}</p>
        </div>
        <div class="back-home" @click="goBack()"><p class="text">重新认证</p></div>
    </div>
</template>
<style lang="scss">
@import "../assets/css/cancel.scss";
</style>
<script setup>
import { useRouter,useRoute } from "vue-router";
import { ref,onMounted } from "vue";
const router=useRouter()
const route=useRoute()
const cannelText=ref('未知错误，请返回重新认证')
const findCode=ref('')
const cannelCode=ref([
    {"code":"66660004","value":"无法确认为同一人"},
    {"code":"66660010","value":"姓名和身份证不一致，请确认"},
    {"code":"-5008","value":"声音未能识别，请大声慢读一遍数字"},
    {"code":"-5018","value":"未识别到指定动作"},
    {"code":"-5023","value":"光线太强，请到室内识别"},
    {"code":"999999","value":"网络不给力，请稍后重试"},
    {"code":"66660000","value":"请求参数异常"},
    {"code":"66660002","value":"服务不可用（已欠费或主动停服，请联系技术支持确认）"},
    {"code":"66660003","value":"试用版最大刷脸次数已超"},
    {"code":"66660005","value":"此证件信息不支持校验"},
    {"code":"66660011","value":"未完成认证或认证结果已过期"},
    {"code":"66660015","value":"姓名或身份证号格式不正确"},
    {"code":"66660016","value":"视频或图片异常"},
    {"code":"66660017","value":"验证次数超限"},
    {"code":"66660018","value":"操作超时，请退出重试"},
    {"code":"66660023","value":"请确保本人操作且正脸对框"},
    {"code":"66660035","value":"未识别到人脸，请确保正脸对框且清晰完整"},
    {"code":"66660037","value":"照片出现多张脸"},
    {"code":"66660041","value":"脸部有遮挡或闭眼，请重试"},
    {"code":"66660044","value":"应用服务版本过低，请升级"},
    {"code":"66660046","value":"比对源照片无法处理"},
    {"code":"66662001","value":"无此授权结果"},
    {"code":"66665001","value":"请确保本人操作且正脸对框"},
    {"code":"400101","value":"签名校验不通过"},
    {"code":"400103","value":"IP 未加入白名单列表（请线下联系技术支持进行配置）"},
    {"code":"400106","value":"不合法请求"},
    {"code":"400506","value":"请求频率过高，请稍后再试"},
    {"code":"400600","value":"【微信】下载视频失败，请重试"},
    {"code":"400601","value":"视频或图片异常"},
])
onMounted(()=>{
    findCode.value=route.query.code
    console.log(findCode.value)
    cannelCode.value.forEach((item)=>{
        if(findCode.value==item.code){
            console.log(item.code)
            cannelText.value=item.value
        }
    })
})
function goBack(){
    router.push('/')
}
</script>