<template>
  <topNav />
  <div id="app">
    <router-view></router-view>
  </div>
  <bottomNav />
</template>

<script setup>
import topNav from "./components/topNav.vue";
import bottomNav from "./components/bottomNav.vue";
import { onMounted } from "vue";
import useStore from "./store/store";
import { storeToRefs } from "pinia";
const counterStore = useStore();
onMounted(() => {
  uuid() 
  counterStore.uuid=uuid()
  // console.log(counterStore.uuid)
  // document.oncontextmenu = new Function("event.returnValue=false");
  // document.addEventListener("keydown", function (e) {
  //       if (e.key == "F12") {
  //         e.preventDefault(); // 如果按下键F12,阻止事件
  //       }
  //   });
});

  function uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;
}

</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body{
  overflow: hidden;
}
</style>
