/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
import QS from "qs";
import router from "../router/index.js";
import { showNotify } from 'vant';
import useStore from "../store/store.js";
import pinia from '../store/index.js'
const counterStore = useStore(pinia);
axios.defaults.baseURL = "https://api.initap.com/";
// axios.defaults.baseURL = "https://firmly-hardy-muskox.ngrok-free.app";
// axios.defaults.baseURL = "http://192.168.50.93:8090/";
axios.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = localStorage.getItem("token") ? localStorage.getItem("token") : "";
    config.headers["x-platform"] = "pc";
    config.headers["x-device-id"] = counterStore.uuid;
    config.headers["x-timestamp"] = parseInt((new Date().getTime() / 1000).toString());
    config.headers["x-device-model"] = "pc";
    config.headers.sign = "no-sign";
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response); //进行中
    } else {
      showNotify(response.msg);
      return Promise.reject(response); //失败
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          showNotify(error.response.data.msg);
          localStorage.removeItem('token')
          break;
        case 403:
          localStorage.clear();
          showNotify(error.response.data.msg);
          break;
        case 404:
          showNotify("请求失败");
          break;
        // 其他错误，直接抛出错误提示
        default:
      }
      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} headers [请求时携带的头]
 * @param {Object} params [请求时携带的参数]
 */
const $get = (url, headers, params) => {
  return new Promise((resolve, reject) => {
    // debugger
    axios
      .get(url, { headers, params: params })
      .then((res) => {
        if (res.data.status != 0) {
          showNotify(res.data.msg);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $post = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params) //是将对象 序列化成URL的形式，以&进行拼接
      .then((res) => {
        if (res.data.status != 0) {
          showNotify(res.data.msg);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
//下面是将get和post方法挂载到vue原型上供全局使用、
// vue2.x中是通 Vue.prototype 来绑定的，像这样Vue.prototype.$toast = Toast。在vue3中取消了Vue.prototype，推荐使用globalProperties来绑定，
export default {
  install: (app) => {
    app.config.globalProperties["$get"] = $get;
    app.config.globalProperties["$post"] = $post;
    app.config.globalProperties["$axios"] = axios;
  },
};
