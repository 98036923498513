import {createI18n} from 'vue-i18n'
import messages from './index'
import { locale } from 'core-js';
const language=((navigator.language?navigator.language:navigator.userLanguage)||'ch').toLowerCase();
const i18n=createI18n({
    fallbackLocale : 'ch',
    globalInjection:true,
    legacy:false,
    locale:'ch',
    messages
})
export default i18n