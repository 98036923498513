import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import http  from './request/request.js'
import i18n from './lang/i18n'
import pinia from './store/index'
import dayjs from 'dayjs'
import Vant from 'vant'
import 'vant/lib/index.css';
const app = createApp(App)
app.use(http)
app.use(i18n)
app.use(Vant)
app.config.globalProperties.$dayjs=dayjs
app.use(pinia)
app.use(router).mount('#app')