import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../view/index.vue'
import HomeView from '../view/HomeView.vue'
import SuccessView from '../view/SuccessView.vue'
import CannelView from '../view/cancel.vue'
const routerHistory = createWebHistory()


// 定义路由
const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/cancel',
    name: 'cancel',
    component: CannelView
  }
]

// 创建路由器
const router = createRouter({
  history: routerHistory,
  routes: routes
})


export default router;