<template>
  <div class="home-container">
    <div class="home-container-nav">
      <van-nav-bar :safe-area-inset-top="true" :border="false" title="实名认证" />
    </div>
    <div class="home-container-real-text">
      <p class="font">实名认证</p>
      <p class="font-small">
        根据相关法律法规的规定，您需要通过实名认证后方可使用我们的部分服务。
      </p>
    </div>
    <div class="home-container-real-input">
      <div class="user-input-content">
        <input type="text" v-model="userName" class="user-name" placeholder="请填写本人的真实姓名" />
      </div>
      <div class="user-input-content">
        <input type="text" v-model="userID" class="user-name" placeholder="请填写本人的身份证号码" />
      </div>
    </div>
    <div class="go-captcha-wrap" v-show="machineShow">
      <GoCaptchaBtnDialog class="go-captcha-btn" v-model:value="captStatus" width="100%" height="50px" :image-base64="captBase64" :thumb-base64="captThumbBase64" :msg="isShow" @confirm="handleConfirm" @refresh="handleRequestCaptCode" />
    </div>
    <div v-show="realSubmit" class="home-container-real-submit" @click="realName()">
      <p class="font">{{defaultText}}</p>
    </div>
    <a v-show="!realSubmit" :href=realLink target="_blank" class="home-container-real-submit blue" @click="checkReal()">
      <p class="font">前往支付宝认证</p>
    </a>
    <!-- <div v-show="realMethod==='alipay'" class="home-container-real-submit" @click="aliRealName()">
      <p class="font">{{defaultText}}</p>
    </div> -->
    <div v-show="manualCheck" class="home-container-real-submit second" @click="checkReal()">
      <p class="font">已成功认证</p>
    </div>
    <div class="secure-display">
      <img class="icon" src="../assets/img/secure.svg" alt="" />
      <p class="font">安全保障中，信息仅用于身份确认</p>
    </div>
    <div v-show="realMethod==='alipay'" class="secure-display">
      <img class="icon" src="../assets/img/secure.svg" alt="" />
      <p class="font">认证成功后请返回当前页面，如遇异常请点击<span class="bold">已成功认证</span></p>
    </div>
    <a v-show="realMethod!='alipay'" href="https://browser.qq.com/" class="default-browser">
      <img class="icon" src="../assets/img/browser.svg" alt="">
      <p class="default-browser0-text">如遇认证失败请<span>下载QQ浏览器</span>进行认证</p>
    </a>
    <van-loading v-show="maskShow" type="spinner" color="#1989fa" />
    <van-overlay :show="maskShow" />
    <van-dialog v-model:show="diaShow" title="确认身份信息" @confirm="realMethod==='alipay'?aliRealName():submitReal()" show-cancel-button>
      <p class="confirm-text">{{ userName }}</p>
      <p class="confirm-text">{{ userID }}</p>
    </van-dialog>
  </div>
</template>
<style lang="scss">
@import '../assets/css/utils.scss';
@import '../assets/css/home.scss';
</style>
<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue'
import useStore from '../store/store.js'
import { storeToRefs } from 'pinia'
import { showNotify } from 'vant'
import { useRoute } from 'vue-router'
import { showConfirmDialog } from 'vant'
import Qs from 'qs'
import GoCaptchaBtnDialog from './GoCaptchaBtnDialog.vue'
import _ from 'lodash'

const counterStore = useStore()
const { proxy } = getCurrentInstance()
const userName = ref('')
const userID = ref('')
const route = useRoute()
const maskShow = ref(false)
const diaShow = ref(false)
const isReal = ref(false)

const captBase64 = ref('')
const captThumbBase64 = ref('')
const captStatus = ref('default')
const isShow = ref('')
const machineShow = ref(false)
const defaultText = ref('立即认证')
const realMethod = ref('')
const realInterval = ref(null)
const checkTrue = ref(false)
const faceID = ref('')
const manualCheck = ref(false)
const overTimeout = ref(null)
const realSubmit = ref(true)
const realLink = ref('')
onMounted(() => {
  getUser()
  // if (route.query.token) {
  //   localStorage.setItem('token', route.query.token)
  //   counterStore.token = route.query.token
  //   getUser()
  //   removeArgument()
  // } else {
  //   if (localStorage.getItem('token')) {
  //     getUser()
  //   } else {
  //     showNotify('当前网络异常，请重新打开该页面')
  //   }
  // }
  if (route.query.realType) {
    realMethod.value = route.query.realType
  }
})

function getUser() {
  let data = {}
  proxy
    .$get('api/member/info', {}, data)
    .then((response) => {
      // showNotify(response.recordset.id);
      if (response.recordset.is_real_name_verified === true) {
        isReal.value = true
      }
    })
    .catch((error) => {})
}

function realName() {
  maskShow.value = true
  if (userName.value === '') {
    showNotify('请输入真实的名字')
    maskShow.value = false
  } else if (userID.value === '') {
    showNotify('请输入本人身份证号码')
    maskShow.value = false
  } else {
    if (isReal.value === true) {
      showNotify('已实名认证，请勿重复操作')
      maskShow.value = false
    } else {
      maskShow.value = false
      diaShow.value = true
    }
  }
}

function removeArgument() {
  var url = window.location.href

  if (url.indexOf('?') != -1) {
    url = url.replace(/(\?|#)[^'"]*/, '')

    window.history.pushState({}, 0, url)
  }
}

function submitReal() {
  console.log('other')
  maskShow.value = true
  let data = {
    name: userName.value,
    id_number: userID.value,
    type: realMethod.value,
  }
  proxy
    .$post('api/v2/identify', data)
    .then((response) => {
      if (response.status === 0) {
        maskShow.value = false
        defaultText.value = '前往认证中请稍后'
        // faceID.value = response.recordset.face_id
        window.location.href = response.recordset.url
        // if (realMethod.value === 'alipay') {
        //   checkReal()
        //   overTime()
        //   window.open(response.recordset.url)
        //   manualCheck.value = true
        // } else {
        //   window.location.href = response.recordset.url
        // }
        response.recordset.response.recordset.captStatus.value = 'default'
      } else if (response.status === 300001) {
        maskShow.value = false
        showNotify('请进行人机验证')
        machineShow.value = true
        captStatus.value = 'display'
      } else {
        maskShow.value = false
        showNotify(response.msg)
        captStatus.value = 'default'
      }
    })
    .catch((error) => {
      maskShow.value = false
    })
}
async function aliRealName() {
  console.log('ali')
  maskShow.value = true
  let data = {
    name: userName.value,
    id_number: userID.value,
    type: realMethod.value,
  }
  try {
    const response = await proxy.$post('api/v2/identify', data)
    console.log('Response', response)
    if (response.status === 0) {
      maskShow.value = false
      realSubmit.value = false
      realLink.value = response.recordset.url
      faceID.value = response.recordset.face_id
      realInterval.value = setInterval(() => {
        checkReal()
      }, 3000)
      overTime()
      // window.open(response.recordset.url)
      manualCheck.value = true
      response.recordset.response.recordset.captStatus.value = 'default'
    } else if (response.status === 300001) {
      maskShow.value = false
      showNotify('请进行人机验证')
      machineShow.value = true
      captStatus.value = 'display'
    } else {
      maskShow.value = false
      showNotify(response.msg)
      captStatus.value = 'default'
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}
function handleRequestCaptCode() {
  proxy
    .$get(
      'api/behavior/captcha',
      {},
      {
        name: userName.value,
        id_number: userID.value,
      }
    )
    .then((response) => {
      if (response.status === 0) {
        console.log(response)
        captBase64.value = response.recordset.data
        captThumbBase64.value = response.recordset.thumb
      } else {
        console.log('modify to over')
        captStatus.value = 'error'
        maskShow.value = false
        showNotify(response.msg)
      }
    })
    .catch((error) => {
      maskShow.value = false
    })
}

function checkReal() {
  let data = {
    h5faceId: faceID.value,
    type: realMethod.value,
  }
  proxy
    .$post('api/v2/identify/notify', data)
    .then((response) => {
      if (response.recordset.status === '0') {
        clearTimeout(overTimeout.value)
        overTimeout.value = null
        showNotify('认证成功')
        clearInterval(realInterval.value)
        realInterval.value = null
      } else {
      }
    })
    .catch((error) => {})
}

function overTime() {
  overTimeout.value = setTimeout(() => {
    clearInterval(realInterval.value)
    realInterval.value = null
    showNotify('认证超时')
  }, 180000)
}
/**
 * 处理验证码校验请求
 */
function handleConfirm(dots) {
  console.log(dots)
  if (_.size(dots) <= 0) {
    showNotify({
      message: `请进行人机验证再操作`,
      type: 'warning',
    })
    return
  }

  let dotArr = []
  _.forEach(dots, (dot) => {
    dotArr.push(dot.x, dot.y)
  })

  proxy
    .$post('api/behavior/captcha/validate', {
      name: userName.value,
      id_number: userID.value,
      dots: dotArr.join(','),
    })
    .then((response) => {
      if (response.status === 0) {
        console.log(response)
        captStatus.value = 'success'
        diaShow.value = true
      } else {
        handleRequestCaptCode()
      }
    })
    .catch((error) => {
      maskShow.value = false
    })
}
</script>
