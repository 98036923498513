<template>
  <div class="index-container">
    <div class="top-title">
      <p>实名认证</p>
    </div>
    <div class="top-desc">
      <p class="font-small">
        根据相关法律法规的规定，您需要通过实名认证后方可使用我们的部分服务。
      </p>
      <div class="need-real-desc">
        <div class="need-list"><span class="circle"></span>
          <p class="need-font">影音加速高效模式<span class="blue">不需要</span>实名认证</p>
        </div>
        <div class="need-list"><span class="circle"></span>
          <p class="need-font">影音加速兼容模式<span class="blue">需要</span>实名认证</p>
        </div>
        <div class="need-list"><span class="circle"></span>
          <p class="need-font">游戏加速兼容模式<span class="blue">需要</span>实名认证</p>
        </div>
      </div>
    </div>
    <div class="real-name-operation">
      <div class="operation-list alipay" @click="openReal('alipay')">
        <p class="font">支付宝认证</p>
      </div>
      <div class="line"></div>
      <div class="operation-list" @click="openReal('other')">
        <p class="font">网页认证</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import '../assets/css/utils.scss';
@import '../assets/css/index.scss';
</style>
<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue'
import useStore from '../store/store.js'
import { storeToRefs } from 'pinia'
import { showNotify } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import { showConfirmDialog } from 'vant'
const router = useRouter()
const route = useRoute()
// const isReal = ref(false)
const { proxy } = getCurrentInstance()
function openReal(type) {
  router.push({ path: 'home', query: { realType: type } })
}
onMounted(() => {
  // localStorage.setItem(
  //   'token',
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6IjgwMDAwMSIsIkRhdGEiOiJ7XCJkZXZpY2VfaWRcIjpcIjNjM2Y2MGJhLWZmODktNDQ5YS04YWRhLWE2OTYxOWRiNmJmY1wiLFwicGxhdGZvcm1cIjpcInBjXCIsXCJsYXN0X2xvZ2luX2F0XCI6MTcyNTg0ODEzMSxcInJlcXVlc3RfaWRcIjpcIjZlMDQ0N2ZjLTlhY2EtMDQxMS04MmI2LTJkNWY0ZmRjYWExMVwiLFwiaXNfc3BlY2lhbFwiOmZhbHNlLFwiaXBcIjpcIlwifSIsImV4cCI6MTc0MTQwMDEzMX0.Ja9Q06MM6R4h4WCsa3RzBQ2_DorOw5rFpviicxYCWas'
  // )
  if (route.query.token) {
    localStorage.setItem('token', route.query.token)
    counterStore.token = route.query.token
    getUser()
    removeArgument()
  } else {
    if (localStorage.getItem('token')) {
      // getUser()
    } else {
      showNotify('当前网络异常，请重新打开该页面')
    }
  }
})
// function getUser() {
//   let data = {}
//   proxy
//     .$get('api/member/info', {}, data)
//     .then((response) => {
//       // showNotify(response.recordset.id);
//       if (response.recordset.is_real_name_verified === true) {
//         isReal.value = true
//       }
//     })
//     .catch((error) => {})
// }
</script>
